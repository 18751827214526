import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { ApiHandler } from "../../requests/ApiHandler";

interface IDeleteUserDialogProps {
  id: string;
  close: (id?: string) => void;
  isOpen: boolean;
}

export function DeleteUserDialog({
  id,
  close,
  isOpen,
}: IDeleteUserDialogProps) {
  const cancelRef = useRef(null);
  const toast = useToast();

  const onDeleteClick = () => {
    ApiHandler.User.delete(id)
      .then((res) => {
        toast({
          position: "top-right",
          title: "User Deleted",
          description: "Successfully deleted user",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        close(id);
      })
      .catch((err) => {
        toast({
          position: "top-right",
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        close();
      });
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={close}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDeleteClick} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
