import {
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
  Text,
  Flex,
  Tooltip,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
  Spinner,
  Box,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useState, useMemo, useEffect, useRef, useLayoutEffect } from "react";
import { RiDeleteBin6Line, RiDownloadLine } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import styles from "./Document.module.scss";
import { Document } from "../Documents/Document.types";
import { useSearchResult } from "../../hooks/useSearchResult";
import { ApiHandler } from "../../requests/ApiHandler";
import { useDebounce } from "../../hooks/useDebounce";
import { GoSearch } from "@react-icons/all-files/go/GoSearch";
import { AppHelper } from "../../helpers/app-helper";

export function Documents() {
  const [data, setData] = useState<Document[]>(() => []);
  const storeIsLoading = useSearchResult((state: any) => state.isLoading);
  const toggleLoading = useSearchResult((state: any) => state.toggleLoading);

  const [isOpen, setIsOpen] = useState(false);
  const [documentCode, setDocumentCode] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const cancelRef = useRef(null);
  const toast = useToast();
  const [searchDocumentKeyword, setSearchDocumentKeyword] = useState("");
  const debounceKeyword = useDebounce(searchDocumentKeyword, 500);

  const columnHelper = createColumnHelper<Document>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.download, {
        id: "download",
        header: "Download",
        cell: (info) => (
          <Tooltip label="Download file">
            <Link href={info.getValue() as string} target="_blank" download>
              <RiDownloadLine className={styles["table-actions-icon"]} />
            </Link>
          </Tooltip>
        ),
      }),
      columnHelper.accessor((row) => row.code, {
        id: "code",
        header: "Delete",
        cell: (info) => (
          <Tooltip label="Delete file">
            <button
              onClick={() => {
                setIsOpen((prev) => {
                  return !prev;
                });
                setDocumentCode(info.getValue());
              }}
            >
              <RiDeleteBin6Line
                size={"15px"}
                className={styles["table-actions-icon"]}
              />
            </button>
          </Tooltip>
        ),
      }),
      columnHelper.accessor((row) => row, {
        id: "title",
        header: "Title",
        cell: (info) => (
          <Link href={info.row.original.view} target="_blank">
            <span>{info.row.original.title}</span>
          </Link>
        ),
      }),
      columnHelper.accessor((row) => row.type, {
        id: "type",
        header: "Type",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.created, {
        id: "uploadDate",
        header: "Upload Date",
        cell: (info) => <span>{info.getValue().toString()}</span>,
      }),
      columnHelper.accessor((row) => row.lastUpdate, {
        id: "lastUpdateDate",
        header: "Update Date",
        cell: (info) => <span>{info.getValue().toString()}</span>,
      }),
    ],
    [columnHelper]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  ////

  /// use Effect
  useEffect(() => {
    handleSearch();
  }, []);
  ///

  const deleteDocument = () => {
    toggleDeleting();
    ApiHandler.Docs.delete(documentCode)
      .then((res) => {
        toast({
          position: "top-right",
          title: "File deleted",
          description: "Successfully deleted file",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          position: "top-right",
          title: "Ops",
          description: "Something went wrong",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        close();
        toggleDeleting();
        handleSearch();
      });
  };

  const close = () => {
    setIsOpen(!isOpen);
    setDocumentCode("");
  };

  const toggleDeleting = () => {
    setIsDeleting((x) => !x);
  };

  const handleChange = (e: any) => {
    setSearchDocumentKeyword(e.target.value);
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter" && debounceKeyword !== "" && debounceKeyword) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    toggleLoading();
    ApiHandler.Docs.get(searchDocumentKeyword)
      .then((res) => {
        setData(res.data.items);
      })
      .finally(() => {
        toggleLoading();
      });
  };

  useEffect(() => {
    if (debounceKeyword === "" || !debounceKeyword) {
      setData([]);
      return;
    }
  }, [debounceKeyword]);

  const TableMemo = useMemo(() => {
    return (
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {storeIsLoading && (
            <>
              <Tr>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
                <Td>
                  <Skeleton />
                </Td>
              </Tr>
            </>
          )}
          {!storeIsLoading &&
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
    );
  }, [data, storeIsLoading]);

  return (
    <>
      <Stack w={"full"} direction={"column"}>
        <Text fontSize="4xl" fontWeight={"semibold"}>
          Documents List
        </Text>
        <Box w={"30%"}>
          <div className={styles["searchbar-container"]}>
            <button onClick={handleSearch}>
              <GoSearch color="rgb(131,131,131)" />
            </button>
            <input
              id="search-id"
              type="text"
              placeholder="Search documents..."
              onChange={handleChange}
              onKeyDown={handleEnter}
              value={searchDocumentKeyword}
            />
          </div>
        </Box>
        <Stack
          border="1px"
          borderColor="gray.200"
          borderRadius={"md"}
          direction={"row"}
          gap={"1rem"}
        >
          {TableMemo}
        </Stack>
      </Stack>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={close}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete document
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button isDisabled={isDeleting} onClick={close}>
                Cancel
              </Button>
              <Button
                isDisabled={isDeleting}
                colorScheme="red"
                onClick={deleteDocument}
                ml={3}
              >
                {isDeleting && <Spinner />}
                {!isDeleting && <span>Delete</span>}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
