import axios from "axios";

export class DashboardRequests {
  BASE_URL = "";

  constructor(url?: string) {
    this.BASE_URL = url || this.BASE_URL;
  }

  search = async (search: string) => {
    return await axios.get(this.BASE_URL + "/search", {
      params: { q: search },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  keywords = async (query: string) => {
    return await axios.get(this.BASE_URL + "/keywords", {
      params: { query },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };
}
