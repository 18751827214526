import { IUserTableActionsProps } from "./UserTableActions.types";
import styles from "./UserTableActions.module.scss";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri";
import { IconButtonWrapper } from "../IconButtonWrapper";
import { Tooltip } from "@chakra-ui/react";

export function UserTableActions({
  editCallback,
  deleteCallback,
}: IUserTableActionsProps) {
  return (
    <>
      <div className={styles["user-table-action-container"]}>
        <Tooltip label="Edit user">
          <IconButtonWrapper
            className="user-edit-user-button"
            onClick={editCallback}
          >
            <RiEdit2Line size={"15px"} />
          </IconButtonWrapper>
        </Tooltip>
        <Tooltip label="Delete user">
          <IconButtonWrapper onClick={deleteCallback}>
            <RiDeleteBin6Line size={"15px"} />
          </IconButtonWrapper>
        </Tooltip>
      </div>
    </>
  );
}
