import "./App.scss";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Login } from "./pages/Login/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Users } from "./pages/Users";
import { UploadDocument } from "./pages/UploadDocument";
import { Logout } from "./pages/Logout";
import { ChakraProvider } from "@chakra-ui/react";
import axios from "axios";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { PageNotFound } from "./pages/404/404";
import { Documents } from "./pages/Documents";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute premissionKey={"dashboard"}>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute premissionKey={"dashboard"}>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/users"
                element={
                  <PrivateRoute premissionKey={"users"}>
                    <Layout>
                      <Users />
                    </Layout>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/files"
                element={
                  <PrivateRoute premissionKey={"documents"}>
                    <Layout>
                      <Documents />
                    </Layout>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/upload-document"
                element={
                  <PrivateRoute premissionKey={"upload"}>
                    <Layout>
                      <UploadDocument />
                    </Layout>
                  </PrivateRoute>
                }
              ></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/logout" element={<Logout />}></Route>
              <Route path="*" element={<PageNotFound />}></Route>
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
