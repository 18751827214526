import { create } from "zustand";

export const useSearch = create((set) => ({
  input: "",
  slectionStart: 0,
  updateInput: (input: string) =>
    set((state: any) => {
      const { result, selectionStart } = getInput(state.input, input);
      return { input: result, selectionStart: selectionStart };
    }),
  changeInput: (input: string) => set((state: any) => ({ input: input })),
}));

function getInput(
  state: string,
  additionalCharacter: string
): { result: string; selectionStart: number } {
  const inputEl = document.getElementById("search-id") as HTMLInputElement;
  if (!inputEl) return { result: state, selectionStart: 0 };

  const result = [
    state.slice(0, inputEl.selectionStart as number),
    state.slice(inputEl.selectionStart as number),
  ];
  result[0] += additionalCharacter;

  return {
    result: result.join(""),
    selectionStart: inputEl.selectionStart! + 1,
  };
}
