import { ILogoutProps } from "./Logout.types";
import { useNavigate } from "react-router";

import {
  Card,
  Button,
  Stack,
  CardHeader,
  Heading,
  CardBody,
  Container,
} from "@chakra-ui/react";
import { Logo } from "../Login/Logo";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useEffect } from "react";

export function Logout({}: ILogoutProps) {
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage({}, "user");

  const navigateToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    setUser({});
  }, []);
  return (
    <>
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
          </Stack>
        </Stack>
        <Card align="center" mt={"2.5"}>
          <CardHeader>
            <Heading size="md">Logged out successfully</Heading>
          </CardHeader>
          <CardBody>
            <Stack spacing="6">
              <Button colorScheme="teal" onClick={navigateToLogin}>
                Sign in
              </Button>
            </Stack>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
