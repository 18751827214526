import { create } from "zustand";
import { ApiHandler } from "../requests/ApiHandler";

export const useSearchResult = create((set) => ({
  results: [],
  sectionsCount: 0,
  documentsCount: 0,
  occurrecesCount: 0,
  isLoading: false,
  run: (input: string) =>
    ApiHandler.Dashboard.search(input).then((res) => {
      set({
        results: res?.data?.items,
        isLoading: false,
        sectionsCount: res?.data?.sectionsCount,
        documentsCount: res?.data?.documentsCount,
        occurrecesCount: res?.data?.occurrecesCount,
      });
    }),
  toggleLoading: () => set((state: any) => ({ isLoading: !state.isLoading })),
}));
