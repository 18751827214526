import { IUploadDocumentProps } from "./UploadDocument.types";
import style from "./UploadDocument.module.scss";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiSave2Line } from "react-icons/ri";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { useSearchResult } from "../../hooks/useSearchResult";
import { ApiHandler } from "../../requests/ApiHandler";
import { AppHelper } from "../../helpers/app-helper";

export function UploadDocument({}: IUploadDocumentProps) {
  const [files, setFiles] = useState<File[]>([]);
  const toast = useToast();
  const storeIsLoading = useSearchResult((state: any) => state.isLoading);
  const [isLoading, setIsLoading] = useState(false);
  const toggleLoading = useSearchResult((state: any) => state.toggleLoading);

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 25,
    multiple: true,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/bmp": [],
      "image/png": [],
    },
  });
  const onUpload = useCallback(() => {
    let formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files${index}`, file);
    });

    toggleLoading();
    ApiHandler.Docs.upload(formData).then((res) => {
      toggleLoading();
      setFiles([]);
      toast({
        position: "top-right",
        title: "Files Uploaded",
        description: "Successfully uploaded files",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    });
  }, [files]);

  useEffect(() => {
    setIsLoading(storeIsLoading);
  }, [storeIsLoading]);

  return (
    <>
      <div className={style["upload-document-container"]}>
        <h1> Upload Document</h1>
        <div {...getRootProps()} className={style["dropzone"]}>
          <input {...getInputProps()} />
          <p>
            Drag 'n' drop some files here, or click to select files, max 25
            files at time
          </p>
        </div>
        <aside>
          <h4>Files</h4>
          <ul>
            {files.map((file: any) => {
              return <li key={file.path}>{file.path}</li>;
            })}
          </ul>
        </aside>
        <div>
          <Button
            type="submit"
            colorScheme="blue"
            onClick={onUpload}
            isDisabled={isLoading}
            leftIcon={<RiSave2Line size={"20px"} color="white" />}
            mr={3}
          >
            {isLoading && <Spinner />}
            {!isLoading && <span>Save</span>}
          </Button>
        </div>
      </div>
    </>
  );
}
