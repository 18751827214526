import { SubmitHandler, useForm } from "react-hook-form";
import { ILoginUserData } from "./Login.types";

import styles from "./Login.module.scss";
import variables from "./../../variables.module.scss";
import { useNavigate } from "react-router";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControl,
  HStack,
  Input,
  Stack,
  Text,
  Button,
  Heading,
  FormLabel,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import { ApiHandler } from "../../requests/ApiHandler";

export function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginUserData>({
    defaultValues: { username: "", password: "" },
    mode: "onSubmit",
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<ILoginUserData> = (data) => {
    setIsLoading(true);
    ApiHandler.Auth.login(data.username, data.password)
      .then((res) => {
        navigate("/dashboard");
        setIsLoading(false);
      })
      .catch((err) => {
        openToast(err.response.data.message);
        setIsLoading(false);
      });
  };

  const openToast = (message: string) => {
    toast({
      position: "top-right",
      title: "Error",
      description: message,
      status: "error",
      duration: 1500,
      isClosable: true,
    });
  };

  return (
    <>
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            {/* <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Log in to your account
              </Heading>
              <HStack spacing="1" justify="center">
                <Text color="muted">Don't have an account?</Text>
                <Button variant="link" colorScheme="blue">
                  Sign up
                </Button>
              </HStack>
            </Stack> */}
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg-surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <form
              onSubmit={(e) => {
                handleSubmit(onSubmit)(e).catch(() => {});
              }}
            >
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl
                    isInvalid={!!errors.username || !!errors.password}
                  >
                    <FormLabel htmlFor="username">Email</FormLabel>
                    <Input
                      id="username"
                      className={
                        errors.username ? styles["invalid"] : styles["none"]
                      }
                      type="username"
                      {...register("username", {
                        required: true,
                        pattern: /^\S+@\S+\.\S+$/,
                      })}
                    />
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      id="password"
                      type="password"
                      {...register("password", {
                        required: true,
                      })}
                    />
                    {Object.keys(errors).length !== 0 && (
                      <span className={styles["invalid-text"]}>
                        Wrong email or password
                      </span>
                    )}
                  </FormControl>
                </Stack>
                <HStack justify="space-between">
                  <Checkbox defaultChecked>Remember me</Checkbox>
                  <Button variant="link" colorScheme="blue" size="sm">
                    Forgot password?
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Button
                    isDisabled={isLoading}
                    type="submit"
                    colorScheme="purple"
                    variant="solid"
                  >
                    {isLoading && <Spinner />}
                    {!isLoading && <span>Sign In</span>}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
