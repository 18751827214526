import { IHeaderProps } from "./Header.types";
import styles from "./Header.module.scss";
import { HiOutlinePlusCircle } from "@react-icons/all-files/hi/HiOutlinePlusCircle";
import { AiOutlineUser } from "@react-icons/all-files/ai/AiOutlineUser";
import { Searchbar } from "../Searchbar";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { RiFileList3Line, RiHome3Line, RiLogoutBoxRLine } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { RxTextNone, RxText } from "react-icons/rx";
import { FiDownload } from "react-icons/fi";
import { IconButtonWrapper } from "../IconButtonWrapper";
import { ApiHandler } from "../../requests/ApiHandler";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useMenu } from "../../hooks/useMenu";
import { useSearchResult } from "../../hooks/useSearchResult";
import { AppHelper } from "../../helpers/app-helper";

export function Header({}: IHeaderProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState<number>(0);
  const navigate = useNavigate();
  const showSymbols = useMenu((state: any) => state.showSymbols);
  const toggleShowSymbols = useMenu((state: any) => state.toggleShowSymbols);

  const [showHiddenText, setShowHiddenText] = useState(true);
  const stateIsLoading = useSearchResult((state: any) => state.isLoading);

  useEffect(() => {
    if (ref.current) {
      setSticky(ref.current.offsetTop);
    }

    handleHiddedTextToogle();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [sticky]);

  const handleScroll = () => {
    if (ref.current) {
      if (window.pageYOffset >= sticky) {
        ref.current.classList.add(styles["nav-fixed"]);
      } else {
        ref.current.classList.remove(styles["nav-fixed"]);
      }
    }
  };

  const navigateToUsers = () => {
    navigate("/users");
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  const navigateToUploadDocument = () => {
    navigate("/upload-document");
  };

  const navigateToFiles = () => {
    navigate("/files");
  };

  const navigateToLogout = () => {
    ApiHandler.Auth.logout()
      .then((res) => {
        navigate("/logout");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/logout");
        }
      });
  };

  const isDashboard = () => {
    var pathArray = window.location.pathname.split("/");
    return (
      pathArray[pathArray.length - 1] === "dashboard" ||
      pathArray[pathArray.length - 1] === ""
    );
  };

  const handleHiddedTextToogle = () => {
    const elements = document.getElementsByClassName("vanish");

    for (let i = 0; i < elements.length; i++) {
      if (!showHiddenText) {
        elements[i].classList.add("show");
      } else {
        elements[i].classList.remove("show");
      }
    }

    setShowHiddenText((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    if (stateIsLoading) {
      setShowHiddenText(false);
    }
  }, [stateIsLoading]);

  return (
    <>
      <div className={`${styles["header-container"]} no-print`}>
        <div className={styles["header-informative"]}>
          <h2 onClick={navigateToDashboard}>TERA Project</h2>
          <div>Search engine</div>
        </div>
        <div ref={ref} className={styles["header-content"]}>
          <div className={styles["header-searchbar"]}>
            {isDashboard() && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<RxHamburgerMenu />}
                  variant="ghost"
                />
                <MenuList>
                  {!showSymbols && (
                    <MenuItem onClick={toggleShowSymbols} icon={<BsEye />}>
                      Show symbols
                    </MenuItem>
                  )}
                  {showSymbols && (
                    <MenuItem onClick={toggleShowSymbols} icon={<BsEyeSlash />}>
                      Hide symbols
                    </MenuItem>
                  )}

                  {!showHiddenText && (
                    <MenuItem
                      onClick={() => {
                        handleHiddedTextToogle();
                      }}
                      icon={<RxText />}
                    >
                      Show hidden text
                    </MenuItem>
                  )}
                  {showHiddenText && (
                    <MenuItem
                      onClick={() => {
                        handleHiddedTextToogle();
                      }}
                      icon={<RxTextNone />}
                    >
                      Hide hidden text
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() => {
                      window.print();
                    }}
                    icon={<FiDownload />}
                  >
                    Print/Save results
                  </MenuItem>
                </MenuList>
              </Menu>
            )}

            <Searchbar />
          </div>
          <div className={styles["header-user-container"]}>
            <Tooltip label="Dashboard">
              <button>
                <IconButtonWrapper onClick={navigateToDashboard}>
                  <RiHome3Line size={"20px"} />
                </IconButtonWrapper>
              </button>
            </Tooltip>

            {AppHelper.Roles.check("upload") && (
              <Tooltip label="Upload documents">
                <button>
                  <IconButtonWrapper onClick={navigateToUploadDocument}>
                    <HiOutlinePlusCircle size={"20px"} />
                  </IconButtonWrapper>
                </button>
              </Tooltip>
            )}

            {AppHelper.Roles.check("users") && (
              <Tooltip label="Users">
                <button>
                  <IconButtonWrapper
                    id="header-user-icon-button"
                    onClick={navigateToUsers}
                  >
                    <AiOutlineUser size={"20px"} />
                  </IconButtonWrapper>
                </button>
              </Tooltip>
            )}

            {AppHelper.Roles.check("documents") && (
              <Tooltip label="Documents list">
                <button>
                  <IconButtonWrapper
                    id="header-user-icon-button"
                    onClick={navigateToFiles}
                  >
                    <RiFileList3Line size={"20px"} />
                  </IconButtonWrapper>
                </button>
              </Tooltip>
            )}

            <Tooltip label="Logout">
              <button>
                <IconButtonWrapper onClick={navigateToLogout}>
                  <RiLogoutBoxRLine size={"20px"} />
                </IconButtonWrapper>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
