import axios from "axios";

export class UploadDocsRequest {
  BASE_URL = "";

  constructor(url?: string) {
    this.BASE_URL = url || this.BASE_URL;
  }

  upload = async (files: FormData) => {
    return await axios.post(this.BASE_URL + "/upload", files, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  get = async (query: string) => {
    return await axios.get(this.BASE_URL + "/documents", {
      params: { query },
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  delete = async (code: string) => {
    return await axios.delete(this.BASE_URL + "/deletedocument/" + code, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };
}
