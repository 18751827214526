import { AuthRequest } from "./AuthRequest";
import { DashboardRequests } from "./DashboardRequests";
import { UploadDocsRequest } from "./UploadDocsRequest";
import { UserRequest } from "./UserRequest";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://tera.cnr.it/Api/c.php"
    : "/Api/c.php";

export class ApiHandler {
  static get Auth() {
    return new AuthRequest(BASE_URL);
  }

  static get User() {
    return new UserRequest(BASE_URL);
  }

  static get Dashboard() {
    return new DashboardRequests(BASE_URL);
  }

  static get Docs() {
    return new UploadDocsRequest(BASE_URL);
  }
}
