import { IFooterProps } from "./Footer.types";
import styles from "./Footer.module.scss";

export function Footer({}: IFooterProps) {
  return (
    <>
      <div id="footer" className={`${styles["footer-container"]} no-print`}>
        <span>
          Made by{" "}
          <a
            href="https://www.greenteam.it/"
            target={"_blank"}
            rel="noreferrer"
          >
            GreenTeam
          </a>
        </span>
        <span>
          <a href="/#">Cookies</a> <a href="/#">Privacy</a>{" "}
          <a href="/#">Terms and Conditions</a>
        </span>
      </div>
    </>
  );
}
