import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { UserTableActions } from "../../components/UserTableActions";
import { RiUserAddLine } from "react-icons/ri";
import { User } from "../../types/User.types";
import { IUserRoles, IUsersProps } from "./Users.types";

import { UserModal } from "../../components/UserModal";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Stack,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  TableContainer,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { ApiHandler } from "../../requests/ApiHandler";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { AppHelper } from "../../helpers/app-helper";

export function Users({}: IUsersProps) {
  const [data, setData] = useState<User[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<IUserRoles[]>([]);

  const columnHelper = createColumnHelper<User>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <UserTableActions
            editCallback={() => {
              onEditClick(props.row);
            }}
            deleteCallback={() => {
              onDeleteClick(props.row);
            }}
          />
        ),
      }),
      columnHelper.accessor((row) => row.id, {
        id: "id",
        header: "Id",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: "Name",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.surname, {
        id: "surname",
        header: "Surname",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      // columnHelper.accessor((row) => row.fiscalCode, {
      //   id: "fiscalcode",
      //   header: "Fiscal Code",
      //   cell: (info) => <span>{info.getValue()}</span>,
      // }),
      columnHelper.accessor((row) => row.email, {
        id: "email",
        header: "Email",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor((row) => row.email, {
        id: "password",
        header: "Password",
        cell: (info) => <span>***********</span>,
      }),
      columnHelper.accessor((row) => row.role, {
        id: "role",
        header: "Role",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
    ],
    [columnHelper]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  ////

  function onEditClick(row: Row<User>) {
    setModalData(row.original);
    setShowAddUserModal(true);
  }

  function onAddClick() {
    setModalData({
      id: undefined,
      name: "",
      surname: "",
      fiscalCode: "",
      email: "",
      password: "",
      role: "",
    });
    setShowAddUserModal(true);
  }

  function onDeleteClick(row: Row<User>) {
    setModalData(row.original);
    toggleDeleteModal();
  }

  ////

  const toggleModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };

  const toggleDeleteModal = (id?: string) => {
    if (id) {
      const filterData = data.filter((element) => {
        return element.id !== id;
      });
      setData(filterData);
    }
    setShowDeleteModal(!showDeleteModal);
  };

  useEffect(() => {
    if (showAddUserModal) return;
    setIsLoading(true);
    ApiHandler.User.get().then((res) => {
      setData(res.data.data);
      setIsLoading(false);
    });
    ApiHandler.User.roles().then((res) => {
      setRoles(res.data.data);
    });
  }, [showAddUserModal]);

  ////

  return (
    <>
      <Stack w={"full"} direction={"column"}>
        <Text fontSize="4xl" fontWeight={"semibold"}>
          Users
        </Text>
        <Stack direction={"row"} justifyContent={"end"}>
          <Button
            maxW={"10%"}
            leftIcon={<RiUserAddLine color="white" size={"20px"} />}
            colorScheme="teal"
            variant="solid"
            onClick={onAddClick}
          >
            Add User
          </Button>
        </Stack>
        <Stack
          border="1px"
          borderColor="gray.200"
          borderRadius={"md"}
          direction={"row"}
          gap={"1rem"}
        >
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {isLoading && (
                <>
                  <Tr>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                    <Td>
                      <Skeleton />
                    </Td>
                  </Tr>
                </>
              )}
              {!isLoading &&
                table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Stack>
      </Stack>
      <UserModal
        close={toggleModal}
        user={modalData}
        isOpen={showAddUserModal}
        roles={roles}
      />
      <DeleteUserDialog
        isOpen={showDeleteModal}
        close={toggleDeleteModal}
        id={modalData?.id!}
      />
    </>
  );
}
