import { useEffect, useState } from "react";

function useLocalStorage(defaultValue: object, key: string) {
  const [value, setValue] = useState(() => {
    let persistentValue = sessionStorage.getItem(key);

    if (!persistentValue) {
      persistentValue = localStorage.getItem(key);
    }

    return persistentValue !== null
      ? JSON.parse(persistentValue)
      : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);
  return [value, setValue];
}

export default useLocalStorage;
