import { create } from "zustand";
import { ApiHandler } from "../requests/ApiHandler";

export const useMenu = create((set) => ({
  showSymbols: true,
  download: (input: string) =>
    ApiHandler.Dashboard.search(input).then((res) => {
      // set({
      //   results: res?.data?.items,
      //   isLoading: false,
      //   sectionsCount: res?.data?.sectionsCount,
      //   documentsCount: res?.data?.documentsCount,
      //   occurrecesCount: res?.data?.occurrecesCount,
      // });
    }),
  toggleShowSymbols: () =>
    set((state: any) => ({ showSymbols: !state.showSymbols })),
}));
