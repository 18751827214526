export default {
  canDeleteDoc: ["ADMIN", "CHIEF", "EDITOR"],
  manageUsers: ["ADMIN", "CHIEF"],
  canUploadDoc: ["ADMIN", "CHIEF", "EDITOR"],
  canSeeImages: ["ADMIN", "CHIEF", "EDITOR"],
  canSearchKeywords: ["ADMIN", "CHIEF", "EDITOR"],
  dashboard: ["ADMIN", "CHIEF", "EDITOR", "READER"],
  users: ["ADMIN", "CHIEF"],
  upload: ["ADMIN", "CHIEF", "EDITOR"],
  documents: ["ADMIN", "CHIEF", "EDITOR"],
} as RoleConfig;

type RoleConfig = {
  [index: string]: string[];
};
