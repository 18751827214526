import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { Logo } from "../Login/Logo";

export function PageNotFound() {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/dashboard");
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
        </Stack>
      </Stack>
      <Card align="center" mt={"2.5"}>
        <CardHeader>
          <Heading size="md">404 Page not found</Heading>
        </CardHeader>
        <CardBody>
          <Stack spacing="6">
            <Button colorScheme="teal" onClick={navigateToLogin}>
              Home Page
            </Button>
          </Stack>
        </CardBody>
      </Card>
    </Container>
  );
}
