import { useForm } from "react-hook-form";
import { IUserModalProps } from "./UserModal.types";
import { User } from "../../types/User.types";
import {
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  FormControl,
  Stack,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { ApiHandler } from "../../requests/ApiHandler";
import styles from "./UserModal.module.scss";

export function UserModal({ close, user, isOpen, roles }: IUserModalProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<User>({
    defaultValues: { ...user },
    mode: "onSubmit",
  });
  const toast = useToast();

  const isNullish = user
    ? Object.values(user).every((value) => {
        if (!value) {
          return true;
        }

        return false;
      })
    : true;

  useEffect(() => {
    reset(user);
  }, [user]);

  const onSubmit = (data: User) => {
    if (user && user.id) {
      ApiHandler.User.update(data)
        .then((res) => {
          toast({
            position: "top-right",
            title: "User Updated",
            description: "Successfully updated user",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          close();
        })
        .catch((err) => {
          toast({
            position: "top-right",
            title: "Error",
            description: "Something went wrong",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    } else {
      ApiHandler.User.create(data)
        .then((res) => {
          toast({
            position: "top-right",
            title: "User Created",
            description: "Successfully created user",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          close();
        })
        .catch((err) => {
          toast({
            position: "top-right",
            title: "Error",
            description: "Something went wrong",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  const onReset = () => {
    reset();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={close}>
        <ModalOverlay />
        <ModalContent>
          {isNullish ? (
            <ModalHeader>Create new user</ModalHeader>
          ) : (
            <ModalHeader>Edit user</ModalHeader>
          )}
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.email || !!errors.password}>
              <ModalBody pb={6}>
                <Stack direction={"column"} gap={"4"}>
                  <Input placeholder="Name" type="text" {...register("name")} />
                  <Input
                    placeholder="Surname"
                    type="text"
                    {...register("surname")}
                  />
                  {/* <Input
                    placeholder="Fiscal code"
                    type="text"
                    {...register("fiscalCode")}
                  /> */}
                  <Input
                    placeholder="Email*"
                    type="text"
                    {...register("email", { required: true })}
                  />
                  <Input
                    placeholder="Password*"
                    type="text"
                    {...register("password", { required: true })}
                  />
                  <label>Role</label>
                  <Select defaultValue={user?.role} {...register("role")}>
                    {roles.map((role) => (
                      <option value={role.name}>{role.name}</option>
                    ))}
                  </Select>
                  {/* <Input placeholder="Role" type="text" {...register("role")} /> */}
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  colorScheme="blue"
                  mr={3}
                >
                  Save
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </ModalFooter>
            </FormControl>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
