import { ITableActionsProps } from "./TableActions.types";
import { RiDownloadLine, RiImageFill, RiLink } from "react-icons/ri";
import { HiMagnifyingGlass } from "react-icons/hi2";
import styles from "./TableActions.module.scss";
import { Link, Tooltip, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { AppHelper } from "../../helpers/app-helper";

export function TableActions({ download, view, images }: ITableActionsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();

  const openToast = () => {
    toast({
      position: "top-right",
      title: "Copied to clipboard",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
  };

  return (
    <>
      {AppHelper.Roles.check("canSeeImages") && (
        <div className={`${styles["table-actions-container"]} no-print`}>
          {images.length > 0 && (
            <Tooltip label="Show images">
              <div>
                <RiImageFill
                  className={styles["table-actions-icon"]}
                  onClick={() => {
                    if (images.length === 0) return;
                    setIsOpen(true);
                  }}
                />
              </div>
            </Tooltip>
          )}
          {isOpen && (
            <div className={`${styles["modal"]}`}>
              <div className={`${styles["modal-content"]}`}>
                <span
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  className={`${styles["close"]}`}
                >
                  &times;
                </span>
                <div className={`${styles["thumbnails"]}`}>
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <a
                        key={index}
                        style={{ width: "200px", height: "300px" }}
                        target={"_blank"}
                        href={image}
                      >
                        <img
                          src={image}
                          style={{ maxWidth: "200px", maxHeight: "300px" }}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </div>
          )}
          {/* <Tooltip label="Download document">
          <Link href={download} target="_blank" download>
            <RiDownloadLine className={styles["table-actions-icon"]} />
          </Link>
        </Tooltip>
        <Tooltip label="Show document">
          <Link href={view} target="_blank">
            <HiMagnifyingGlass className={styles["table-actions-icon"]} />
          </Link>
        </Tooltip> */}
        </div>
      )}
    </>
  );
}
