import { ILayoutProps } from "./Layout.types";
import styles from "./Layout.module.scss";
import { Header } from "../Header";
import { Logo } from "../Logo";
import { Footer } from "../Footer";
import { Sidebar } from "../Sidebar";

export function Layout({ children }: ILayoutProps) {
  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["header"]}>
          <Header />
        </div>
        <div className={styles["content"]}>{children}</div>
        <div className={styles["footer"]}>
          <Footer />
        </div>
      </div>
    </>
  );
}
