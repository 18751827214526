import { ISearchbarProps } from "./Searchbar.types";
import { GoSearch } from "@react-icons/all-files/go/GoSearch";
import styles from "./Searchbar.module.scss";
import { useEffect, useRef, useState } from "react";
import { useSearch } from "../../hooks/useSearch";
import { Input } from "@chakra-ui/react";
import { useSearchResult } from "../../hooks/useSearchResult";
import { useSearchParams } from "react-router-dom";

export function Searchbar() {
  const search = useSearch((state: any) => state.input);
  const selectionStart = useSearch((state: any) => state.selectionStart);
  const [start, setStart] = useState(0);
  const changeInput = useSearch((state: any) => state.changeInput);
  const searchResults = useSearchResult((state: any) => state.run);
  const toggleLoading = useSearchResult((state: any) => state.toggleLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const input = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    changeInput(e.target.value);
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    var pathArray = window.location.pathname.split("/");

    if (
      pathArray[pathArray.length - 1] !== "dashboard" &&
      pathArray[pathArray.length - 1] !== ""
    ) {
      window.location.href = "/dashboard?search=" + search;
    } else {
      toggleLoading();
      searchResults(search);
    }
  };

  useEffect(() => {
    const search = searchParams.get("search");
    if (search && search !== "") {
      changeInput(search);
    }
  }, []);

  useEffect(() => {
    if (input.current) {
      input.current.focus();

      input.current.selectionStart = selectionStart;
      input.current.selectionEnd = selectionStart;
    }
  }, [start]);

  useEffect(() => {
    setStart(selectionStart);
  }, [selectionStart]);

  return (
    <>
      <div className={styles["searchbar-container"]}>
        <button onClick={handleSearch}>
          <GoSearch color="rgb(131,131,131)" />
        </button>
        <input
          ref={input}
          id="search-id"
          type="text"
          placeholder="Search..."
          onChange={handleChange}
          onKeyDown={handleEnter}
          value={search}
        />
      </div>
    </>
  );
}
