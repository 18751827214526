import { ISeachResultDetailProps } from "./SearchResultDetail.types";
import styles from "./SearchResultDetail.module.scss";
import { RiFileList2Line } from "react-icons/ri/index.js";
import { Link } from "@chakra-ui/react";
import React from "react";

export function SearchResultDetail({
  document,
  actions,
  lastUpdate,
}: ISeachResultDetailProps) {
  return (
    <>
      <div className={styles["search-result-deatail-header"]}>
        <RiFileList2Line opacity={0.5} />
        <Link href={actions.view} target="_blank">
          <span>{document.documentTitle}</span>
        </Link>
      </div>
      <div className={styles["search-result-deatail-content"]}>
        <span>{document.sectionTitle}</span>
        <div className={styles["search-result-deatail-content-grid"]}>
          {document.sections?.map((section, index) => (
            <React.Fragment key={index}>
              <span
                dangerouslySetInnerHTML={{
                  __html: section.sectionContentItems[0],
                }}
              ></span>
              <span
                dangerouslySetInnerHTML={{
                  __html: section.sectionContentItems[1],
                }}
              ></span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
}
