import axios from "axios";
import { User } from "../types/User.types";

export class UserRequest {
  BASE_URL = "";

  constructor(url?: string) {
    this.BASE_URL = url || this.BASE_URL;
  }

  get = async (userFilter?: User) => {
    return await axios.get(this.BASE_URL + "/users", {
      params: { ...userFilter },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  create = async (userFilter: User) => {
    let data = this.generateFormData(userFilter);

    return await axios.post(this.BASE_URL + "/users", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  update = async (userFilter: User) => {
    let data = this.generateFormData(userFilter);

    return await axios.put(this.BASE_URL + "/users", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  delete = async (id: string) => {
    return await axios.delete(`${this.BASE_URL}/users?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };

  generateFormData = (userFilter: User): FormData => {
    let data = new FormData();
    data.append("name", userFilter.name);
    data.append("surname", userFilter.surname);
    data.append("fiscalCode", userFilter.fiscalCode);
    data.append("email", userFilter.email);
    if (userFilter.id) {
      data.append("id", userFilter.id);
    }
    data.append("role", userFilter.role);
    data.append("password", userFilter.password);
    return data;
  };

  roles = async () => {
    return await axios.get(this.BASE_URL + "/roles", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  };
}
