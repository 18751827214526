import axios from "axios";

export class AuthRequest {
  BASE_URL = "";

  constructor(url?: string) {
    this.BASE_URL = url || this.BASE_URL;
  }

  async login(username: string, password: string) {
    let data = new FormData();
    data.append("username", username);
    data.append("password", password);
    data.append("grant_type", "password");

    return await axios
      .post(this.BASE_URL + "/login", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
      })
      .then((data) => {
        document.cookie = `teratokencookie=${data.data.data.value}`;
        localStorage.setItem("tera-rl", data.data.data.role);
      });
  }

  async logout() {
    return await axios.post(this.BASE_URL + "/logoff", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      },
    });
  }

  async status() {
    return await axios
      .get(this.BASE_URL + "/status", {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        },
      })
      .catch(() => {
        window.location.href = "/login";
      });
  }
}
