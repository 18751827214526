import { IIconButtonWrapperProps } from "./IconButtonWrapper.types";
import styles from "./IconButtonWrapper.module.scss";

export function IconButtonWrapper({
  children,
  onClick,
  id,
  className,
}: IIconButtonWrapperProps) {
  return (
    <>
      <button
        id={id}
        className={styles["icon-button-wrapper"] + " " + className}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
}
