import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Key, useEffect, useMemo, useState } from "react";
import { TableStruct } from "./Dashboard.types";
import { SearchResultDetail } from "../../components/SearchResultDetail";
import { TableActions } from "../../components/TableActions";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  Box,
  Button,
  Spinner,
  Stack,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useSearch } from "../../hooks/useSearch";
import { useSearchResult } from "../../hooks/useSearchResult";
import { useSearchParams } from "react-router-dom";
import { useMenu } from "../../hooks/useMenu";
import { ApiHandler } from "../../requests/ApiHandler";
import { GoSearch } from "@react-icons/all-files/go/GoSearch";
import styles from "./Dashboard.module.scss";
import { useDebounce } from "../../hooks/useDebounce";
import { AppHelper } from "../../helpers/app-helper";

export function Dashboard() {
  const TAGS = [
    {
      hide: false,
      value: "š",
    },
    {
      hide: false,
      value: "Š",
    },
    {
      hide: false,
      value: "ḫ",
    },
    {
      hide: false,
      value: "Ḫ",
    },
    {
      hide: true,
      value: "ʾ",
    },
    {
      hide: true,
      value: "ʿ",
    },
    {
      hide: true,
      value: "ˀ",
    },
    {
      hide: true,
      value: "ˁ",
    },
    {
      hide: true,
      value: "Ɂ",
    },
    {
      hide: true,
      value: "ʕ",
    },
    {
      hide: true,
      value: "Ā",
    },
    {
      hide: true,
      value: "ā",
    },
    {
      hide: true,
      value: "Â",
    },
    {
      hide: true,
      value: "â",
    },
    {
      hide: true,
      value: "Ḍ",
    },
    {
      hide: true,
      value: "ḍ",
    },
    {
      hide: true,
      value: "Ḏ",
    },
    {
      hide: true,
      value: "ḏ",
    },
    {
      hide: true,
      value: "Ē",
    },
    {
      hide: true,
      value: "ē",
    },
    {
      hide: true,
      value: "Ê",
    },
    {
      hide: true,
      value: "ê",
    },
    {
      hide: true,
      value: "Ġ",
    },
    {
      hide: true,
      value: "ġ",
    },
    {
      hide: true,
      value: "Ĝ",
    },
    {
      hide: true,
      value: "ĝ",
    },
    {
      hide: true,
      value: "Ğ",
    },
    {
      hide: true,
      value: "ğ",
    },
    {
      hide: true,
      value: "Ḥ",
    },
    {
      hide: true,
      value: "ḥ",
    },
    {
      hide: true,
      value: "Ī",
    },
    {
      hide: true,
      value: "ī",
    },
    {
      hide: true,
      value: "Î",
    },
    {
      hide: true,
      value: "î",
    },
    {
      hide: true,
      value: "İ",
    },
    {
      hide: true,
      value: "ı",
    },
    {
      hide: true,
      value: "Ř",
    },
    {
      hide: true,
      value: "ř",
    },
    {
      hide: true,
      value: "Ṣ",
    },
    {
      hide: true,
      value: "ṣ",
    },
    {
      hide: true,
      value: "Ś",
    },
    {
      hide: true,
      value: "ś",
    },
    {
      hide: true,
      value: "Ṭ",
    },
    {
      hide: true,
      value: "ṭ",
    },
    {
      hide: true,
      value: "Ṯ",
    },
    {
      hide: true,
      value: "ṯ",
    },
    {
      hide: true,
      value: "Ū",
    },
    {
      hide: true,
      value: "ū",
    },
    {
      hide: true,
      value: "û",
    },
    {
      hide: true,
      value: "Û",
    },
    {
      hide: true,
      value: "Ẓ",
    },
    {
      hide: true,
      value: "ẓ",
    },
    {
      hide: true,
      value: "ǝ",
    },
  ];

  const searchResults = useSearchResult((state: any) => state.results);
  const [data, setData] = useState(() => [...searchResults]);

  const columnHelper = createColumnHelper<TableStruct>();
  const stateIsLoading = useSearchResult((state: any) => state.isLoading);

  const sectionsCount = useSearchResult((state: any) => state.sectionsCount);

  const documentsCount = useSearchResult((state: any) => state.documentsCount);

  const showSymbols = useMenu((state: any) => state.showSymbols);

  const occurrecesCount = useSearchResult(
    (state: any) => state.occurrecesCount
  );

  const updateSearch = useSearch((state: any) => state.updateInput);
  const changeInput = useSearch((state: any) => state.changeInput);
  const run = useSearchResult((state: any) => state.run);
  const toggleLoading = useSearchResult((state: any) => state.toggleLoading);
  const [keywords, setKeyword] = useState<any>([]);
  const [keywordsSearch, setKeywordSearch] = useState("");
  const keywordsSearchDebounce = useDebounce<string>(keywordsSearch, 500);
  const [keywordsIsLoading, setKeywordsIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: "document",
        cell: (info) => (
          <SearchResultDetail {...info.row.original}></SearchResultDetail>
        ),
      }),
      // columnHelper.accessor("lastUpdate", {
      //   cell: (info) => <span className="no-print">{info.getValue()}</span>,
      // }),
      columnHelper.accessor("actions", {
        cell: (info) => <TableActions {...info.getValue()}></TableActions>,
      }),
    ],
    [columnHelper, searchResults]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    const search = searchParams.get("search");
    if (search && search !== "") {
      run(search);
    }
  }, []);

  useEffect(() => {
    setData(searchResults);
    window.scrollTo(0, 0);
  }, [searchResults]);

  const handleChange = (e: any) => {
    setKeywordSearch(e.target.value);
  };

  const handleEnter = (e: any) => {
    if (
      e.key === "Enter" &&
      keywordsSearchDebounce !== "" &&
      keywordsSearchDebounce
    ) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setKeywordsIsLoading(true);
    ApiHandler.Dashboard.keywords(keywordsSearch)
      .then((res) => {
        if (res.status > 300) return;
        setKeyword(res.data.items);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setKeywordsIsLoading(false);
      });
  };

  useEffect(() => {
    if (keywordsSearchDebounce === "" || !keywordsSearchDebounce) {
      setKeyword([]);
      return;
    }
  }, [keywordsSearchDebounce]);

  const TableMemo = useMemo(() => {
    return (
      <Table>
        <Tbody>
          {stateIsLoading && <Spinner size="xl" />}
          {!stateIsLoading &&
            table.getRowModel().rows.map((row, _index) => (
              <Tr style={{ pageBreakInside: "avoid" }} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td verticalAlign={"baseline"} key={cell.id}>
                    {stateIsLoading ? (
                      cell.column.columnDef.id === "document" ? (
                        <Skeleton count={5} />
                      ) : (
                        <Skeleton count={1} />
                      )
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </Table>
    );
  }, [data, stateIsLoading]);

  const KeywordsMemo = useMemo(() => {
    return keywords?.map(
      (
        tag: { value: string; keyword: string },
        index: Key | null | undefined
      ) => {
        return (
          <Tag
            m={"2px"}
            key={index}
            onClick={(params: any) => {
              changeInput(tag.keyword);
              toggleLoading();
              run(tag.keyword);
            }}
            alignItems={"center"}
            borderRadius="full"
            size={"md"}
            cursor={"pointer"}
            maxW={"100vw"}
          >
            <TagLabel pr={2} pl={2}>
              <span
                dangerouslySetInnerHTML={{
                  __html: tag.value,
                }}
              ></span>
            </TagLabel>
          </Tag>
        );
      }
    );
  }, [keywords]);

  const TAGSMemo = useMemo(() => {
    return TAGS.map((tag, index) => {
      return (
        <Tag
          m={"2px"}
          display={!showSymbols && tag.hide ? "none" : "inline-flex"}
          key={index}
          onClick={(params: any) => {
            updateSearch(params.target.innerText);
          }}
          alignItems={"center"}
          borderRadius="full"
          size={"md"}
          cursor={"pointer"}
          maxW={"100vw"}
        >
          <TagLabel pr={2} pl={2} style={{ fontSize: "1rem" }}>
            {tag.value}
          </TagLabel>
        </Tag>
      );
    });
  }, [showSymbols]);

  return (
    <>
      <Stack w={"full"} direction={"column"}>
        <Box className="no-print" gap={"1rem"} mb={"2rem"}>
          {TAGSMemo}
        </Box>
        <Stack
          className="no-print"
          direction={"row"}
          w={"full"}
          justifyContent={"space-between"}
        >
          <Box w={"80%"}>
            {data && (
              <>
                {!stateIsLoading && (
                  <Text fontWeight={"bold"} color={"rgba(0, 0, 0, 0.5)"}>
                    {`Founded ${occurrecesCount} ${
                      occurrecesCount !== 1 ? "occurrences" : "occurrence"
                    } in ${sectionsCount} ${
                      sectionsCount !== 1 ? "sections" : "section"
                    } in ${documentsCount} ${
                      documentsCount !== 1 ? "documents" : "document"
                    }`}
                  </Text>
                )}
              </>
            )}
          </Box>
          {AppHelper.Roles.check("canSearchKeywords") && (
            <Text fontWeight={"bold"} w={"20%"} color={"rgba(0, 0, 0, 0.5)"}>
              {`Search keywords`}
            </Text>
          )}
        </Stack>

        <Stack gap={"1rem"} direction={"row"} justifyContent={"space-between"}>
          <Stack
            className={`${
              AppHelper.Roles.check("canSearchKeywords")
                ? styles["main-stack"]
                : styles["full-width"]
            }`}
            direction={"row"}
            gap={"1rem"}
            h={"fit-content"}
          >
            {TableMemo}
          </Stack>
          {AppHelper.Roles.check("canSearchKeywords") && (
            <Box className="no-print" w={"20%"}>
              <div className={styles["searchbar-container"]}>
                <button disabled={keywordsIsLoading} onClick={handleSearch}>
                  <GoSearch color="rgb(131,131,131)" />
                </button>
                <input
                  id="search-id"
                  type="text"
                  disabled={keywordsIsLoading}
                  placeholder="Search keywords..."
                  onChange={handleChange}
                  onKeyDown={handleEnter}
                  value={keywordsSearch}
                />
              </div>
              {keywordsIsLoading && <Spinner size="xl" />}
              {KeywordsMemo}
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}
