import { useLayoutEffect } from "react";
import { AppHelper } from "../helpers/app-helper";
import { ApiHandler } from "../requests/ApiHandler";
import { Props } from "./PrivateRoute.types";

export const PrivateRoute = ({ children, ...props }: Props) => {
  useLayoutEffect(() => {
    if (!AppHelper.Roles.check(props.premissionKey)) {
      window.location.href = "/dashboard";
      return;
    }
    ApiHandler.Auth.status();
  });

  return <>{children}</>;
};
