import { Roles } from "../types/roles.types";
import data from "./../roles.config";

export class RoleGuard {
  static check(key: string) {
    const role = Roles[this.hasRole()];

    return (data[key] || []).includes(role);
  }
  static hasRole() {
    const role = localStorage.getItem("tera-rl");
    if (!role || role === "") {
      window.location.href = "/logout";
    }
    return role ?? "";
  }
}
